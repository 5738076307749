.payment {
    width: 100%;
    min-height: 100vh;
    background-image: url(../image/back1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    h2 {
        color: white;

    }
}

//src/index.css
.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: 500;
    font-size: 15px;
}

.pagination-container a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 50px;
    width: 50px;
    font-weight: bolder;
    text-decoration: none;
    transition: background-color .2s;
    border: 1px solid #ddd;
    cursor: pointer;

}

.pagination-container a.active {
    background-color: #007bff;
    color: white;
    border: 1px solid #7cbddb;
}

.pagination-container a.disabled {
    opacity: 0.2;
}

.pagination-container a:hover:not(.active) {
    background-color: rgb(238, 238, 238);
}