.box {
    // width: 100%;
    // background-color: rgba(0, 15, 38, 0.894);
}

.filter {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    background-image: url(../image/back1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

.all-center {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}

.statis-box {
    width: 100%;
    height: 120px;
    line-height: 50px;
    position: relative;

    .txt1 {
        position: absolute;
        right: 40px;
        top: 10px;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: black;
    }

    .txt2 {
        position: absolute;
        right: 40px;
        top: 40px;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: black;
    }

    .txt3 {
        position: absolute;
        right: 40px;
        top: 70px;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: black;
    }

}

.box-table {
    overflow: auto;
    max-height: 80vh;

    table {
        position: relative;
        // width: 100%;
        // position: absolute;
        z-index: 2;
        // left: 50%;
        // top: 50%;
        // transform: translate(-50%, -50%);
        // width: 80%;
        border-collapse: collapse;
        border-spacing: 0;
        box-shadow: 0 2px 15px rgb(64, 64, 64);
        border-radius: 12px 12px 0 0;
        // width: 100% !important;
        border: 1px solid #ddd;
        background-color: white;
        min-width: 70vw;
    }

}

td,
th {
    padding: 15px 20px;
    text-align: center;
}


th {
    background-color: #110047;
    color: #fafafa;
    font-family: 'Open Sans', Sans-serif;
    font-weight: 200;
    text-transform: uppercase;

}

tr {
    width: 100%;
    background-color: #fafafa;
    font-family: 'Montserrat', sans-serif;
}

tr:nth-child(even) {
    background-color: #eeeeee;
}

@media (max-width:768px) {

    .box-table {
        table {
            // width: 2rem !important;
            resize: both;
            top: 40%;
            left: 10%;
        }
    }

}