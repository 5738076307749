
.boxes{
    width: 100%;
    max-height: 100vh;
    background: url(../image/back1.jpg);
     background-repeat: no-repeat;
     background-size: cover;
     background-position: center;
.box-meta{
    margin-bottom: 50px;
    img{
        width: 400px;
        margin-bottom: 25px;
    }
    display: flex;
    flex-direction: column; 
    justify-content: space-around;
    align-items: center;
    h1{
        color: white;
     }
}

}