header {
    background-color: rgba(0, 15, 38, 0.896);
    padding: 15px;
    z-index: 999;
    width: 100%;
    position: relative;
.logo{
    img{
        width: 250px;
    }
}
}
.btn1{
    padding: 15px 17px ;
    background-color: transparent;
    color: white;
    border: 2px solid white;
    font-weight: 700;
    font-size: 15px;
    border-radius: 10px;
}

@media (max-width:768px) {
    header{
        .logo{
img{
    width: 100px;
}
        }
    }
}